import React from 'react';

class CenteredLogo extends React.Component {
    render() {
        return (
            <>
                <img className="floating" src="./logo_large.png" alt=""></img>
                <p>Soon to be home for Tanooki Trade! Please watch this space!</p>
            </>
        );
    }
}

export default CenteredLogo;